import Vue from 'vue'
import ToastVue from './toast.vue'

const ToastConstructor = Vue.extend(ToastVue)
let instance

const Toast = (options = {}) => {
  instance = new ToastConstructor({
    propsData: {},
    data: options,
    // ...options,
  })

  document.body.appendChild(instance.$mount().$el) //this.$el拿到组件实际上的dom，把他挂载到body上
  
  setTimeout(() => {
    document.body.removeChild(instance.$mount().$el)
  }, 3000)  //3秒之后自动移除dom组件
}

export default Toast