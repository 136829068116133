<template>
  <div class="my-messagebox" @click.self="onClickMask">
    <div class="box">
      <h3>title</h3>
      <div class="content">
        messageBox
      </div>
      <div class="btn-group">
        <el-button size="mini" @click="onClickMask"> 取消 </el-button>
        <el-button type="primary" size="mini" @click="onConfirm"> 确定 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    clickFun: {
      type: Function,
      default: null,
    },
    success: {
      type: Function,
      default: () => {}
    },
    cancel: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    onClickMask(){
      // console.log('$options:', this.$options)
      this.$options.clickFun('cancel')

      // this.$options.cancel && this.$options.cancel('cancel')
    },
    onConfirm(){
      this.$options.clickFun && this.$options.clickFun('confirm')
      
      // this.$options.success && this.$options.success('success')
    },
  },
}
</script>

<style lang="scss" scoped>
.my-messagebox{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(0,0,0,.5);
  transition: all .5s; 
  // animation: 'pop-up .6s ease-in-out';
  display: flex;
  justify-content: center;
  align-items: center;
  .box{
    background: #FFFFFF;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 0 3px #00000080;
    text-align: center;
    text-align: left;
    padding: 0 10px 10px;
    // padding-bottom: 10px;
    .content{
      font-size: 14px;
    }
    .btn-group{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>