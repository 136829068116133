<template>
  <gl-card>
    <div style="margin-bottom:10px;">
      <el-button type="primary" icon="el-icon-search" size="mini" @click="onClickToast"> toast </el-button>
    </div>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="onClick('success')"> success </el-button>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="onClick('error')"> error </el-button>
  </gl-card>
</template>

<script>
import Toast from './toast'
import Toast_ from './toast_'
export default {
  methods: {
    onClickToast(){
      Toast({message: '哈哈哈', timestamp: new Date()})
    },
    onClick(type){
      console.log(type)
      Toast_.success('success')
      Toast_.error('error')
    },
  },
}
</script>

<style>

</style>