import store from '@/store'

const baseUrl = process.env.VUE_APP_BASE_API // 'http://your.api.url'
const baseHeader = {
  'Content-Type': 'application/json;charset=UTF-8',
}

const objToString = (params = {}) => {
  let paramsStr = ''
  if(Object.keys(params).length > 0){
    for(const key in params){
      paramsStr += `${key}=${params[key]}&`
    }
    paramsStr = paramsStr.slice(0, paramsStr.length - 1)
  }
  return paramsStr
}

const Request = (options = {}) => {
  let url = options.url || ''
  const {
    method = 'GET',
    data = null,
    headers = {},
    timeout = 60000,
  } = options
  const fetchOptions = {method, headers}

  headers['Content-Type'] = 'application/json; charset=utf-8'
  // headers['Authorization'] = 'accessToken'
  headers['X-Requested-Token'] = store.getters['user/accessToken'] || ''

  if(data){
    if(method.toUpperCase() === 'GET' || method == 'head'){
      url += `?${objToString(data)}`
    }else{
      fetchOptions.body = JSON.stringify(data)
    }
  }
  // 设置超时时间
  const controller = new AbortController()
  const signal = controller.signal
  const timerId = setTimeout(() => console.abort(), timeout)

  return fetch(`${baseUrl}${url}`,{
    ...fetchOptions,
    signal,
  }).catch(error => {
    
    throw new Error('请求失败')

  }).then((response) => {

    clearTimeout(timerId)
    if(response.ok){
      return response.json()
    }
    throw new Error(`请求失败${response.status}`)

  }).then((result) => {

    // if(result.success === true && result.code === 200){
    if(result.code === 200){
      return result
    }
    throw new Error('请求失败2')

  })
}

// Request.prototype.get = function(url, params){
//   return Request({
//     url,
//     method: 'GET',
//     data: params,
//   })
// }

// Request.prototype.post = (url, params)  => {
//   return Request({
//     url,
//     method: 'POST',
//     data: params,
//   })
// }

export default Request