<template>
  <gl-card>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleNotify"> Observer-notify </el-button>
  </gl-card>
</template>

<script>
//创建通告人
class Notifier{
  constructor(){
    this.observerList = []
  }
  add(observer){
    this.observerList.push(observer)
  }
  notify(){
    this.observerList.forEach((observer) => observer.update())
  }
}
//创建观察者
class Observer{
  constructor(name, subject){
    this.name = name
    this.subject = subject
    this.subject.add(this)
  }
  update(){
    console.log(`name: ${this.name}`)
  }
}

export default {
  methods: {
    handleNotify(){
      const notifier = new Notifier()

      const observer1 = new Observer('张山', notifier) 
      const observer2 = new Observer('李四', notifier)
      const observer3 = new Observer('王五', notifier)
      
      notifier.notify()
    }
  },
}
</script>

<style>

</style>