<template>
  <div>
    <gl-card>
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="userName" label="姓名" verify>
          <el-input v-model="formData.userName"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号" verify>
          <el-input v-model="formData.phone"></el-input>
        </el-form-item>
        <el-form-item prop="images" label="图片" :watch="formData.images" verify>
          <gl-drag-upload v-model="formData.images" :oldList="oldList" :limit="9" :autoUpload="true" ref="uploadImage"></gl-drag-upload>
        </el-form-item>
      </el-form> 
      <gl-flex>
        <el-button type="primary" @click="onSubmit" >确认</el-button>
        <el-button @click="$router.go(-1)" >取消</el-button>
      </gl-flex>
    </gl-card>
  </div>
</template>

<script>
export default {
  name: 'componentTemp',
  data(){
    return{
      oldList: [
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683706916555.png',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683712517648.jpg',

        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683772758939.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683772759453.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683772759204.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1684724288097.png',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1684724304508.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1685945009478.jpg',
      ],
      tempData: {
        userName: 'evan-test',
        phone: '18688888888',
        images: [],
      },
      formData: {},
      loading: false,
    }
  },
  watch: {
    'formData.images': {
      handler: function(srcs){
        console.error('srcs:', srcs)
      }
    },
  },
  mounted(){
    this.fetchData
  },
  methods: {
    fetchData(){
      this.formData = {...this.tempData}
    },
    onSubmit(){
      this.$refs.form.validate(async valid => {
        console.log('formData:', this.formData)
        if(!valid) return false
        try{
          const imgArr = await this.$refs.uploadImage.upload()
          console.error('imgArr:', imgArr)
        }catch(e){

        }
      })
    },
  },
}
</script>

<style>

</style>