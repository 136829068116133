// 地址: http://news.558idc.com/464614.html

import Vue from 'vue'
import ToastVue from './toast_.vue'

const ToastConstructor = Vue.extend({
  ...ToastVue
})



const Toast = {
  instanceList: [],
  hide(types){
    for(let instance of this.instanceList){
      if(instance.types == types){
        instance && 
        document.body.removeChild(instance.$el) && Reflect.deleteProperty(this, types)
      }
    }
  },
  success(message){
    if(!this.vmSuccess){
      let toastBox = new ToastConstructor({
        propsData: {
          message,
          type: 'success',
          top: (this.instanceList.length + 1) * 55
        },
      })
      let $Toast = toastBox.$mount()
      this.vmSuccess = $Toast
      this.instanceList.push({...$Toast, types: 'vmSuccess'})
      document.body.appendChild($Toast.$el)
      setTimeout(() => {
        // this.hide('vmSuccess')
        document.body.removeChild($Toast.$el)
      }, 3000)
    }
  },
  error(message){
    if(!this.vmError){
      let ToastBox = new ToastConstructor({
        propsData: {
          message,
          type: 'error',
          top: (this.instanceList.length + 1) * 55
        }
      })
      let $toast = ToastBox.$mount()
      this.vmError = $toast
      this.instanceList.push({...$toast, types: 'vmError'})
      document.body.appendChild($toast.$el)
      setTimeout(() => {
        this.hide('vmError')
      }, 3000)
    }
  }
}



export default Toast