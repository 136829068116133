<template>
  <div>
    <gl-card>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="fetchData"> testFetch-get-获取echarts数据 </el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="postFetch"> testFetch-post-菜单 </el-button>
    </gl-card>
  </div>
</template>

<script>
import request from '@/plugins/service'

const getData = (data = {}) => {
  return request({
    url: '/api/order/parent/getOrderSum',
    method: 'GET',
    data,
  })
}

const postData = (data = {}) => {
  return request({
    url: '/api/user/menu/getMenu',
    method: 'POST',
    data,
  })
}

export default {
  data(){
    return{
      getParams: {
        queryType: '1',
      },
    }
  },
  methods: {
    async fetchData(){
      const res = await getData(this.getParams)
      console.log('res-get:', res)
    },
    async postFetch(){
      const res = await postData()
      console.log('res-post:', res)
    },
  },
}
</script>

<style>

</style>