<template>
  <gl-card>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="onClick"> messageBox </el-button>
  </gl-card>
</template>

<script>
import messageBox from './messageBox'
export default {
  data(){
    return {
    
    }
  },
  methods: {
    onClick(){
      messageBox({
        title: '我是title',
        content: '我是content',
        confirmText: '确定',
        cancelText: '取消',
        click: res => {
          console.log('click-res:', res)
        },
        success: res => {
          console.log('success-res:', res)
        }
      })
      // .then(res => {
      //   console.log('res:', res)
      // })
    }
  }
}
</script>

<style>

</style>