<template>
  <div
    :class="['message-box', 'message-box-' + type]"
    :style="{ top: top + 'px' }"
  >
    <header>
      <span class="close">X</span>
    </header>
    <div class="message--box-content">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    message: {
      type: String,
      default: "this is message box",
    },
    type: {
      validator(value) {
        return ["success", "error", "default"].indexOf(value) !== -1;
      },
      default: "default",
    },
    top: {
      type: Number,
      default: 20,
    },
  },
};
</script>
<style lang="scss" scoped>
.message-box {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  height: 50px;
  background-color: #ccc;
  .close {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
  }
  .message--box-content {
    line-height: 50px;
    text-indent: 2em;
  }
  &.message-box-success {
    background-color: green;
  }
  &.message-box-error {
    background-color: red;
  }
  &.message-box-default {
    background-color: #eee;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>