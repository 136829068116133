<template>
  <div>
    <gl-card>
      <div ref="myChartMeter" :style="{width: '100%', height: '300px'}"></div>
    </gl-card>
    <gl-card>
      <div ref="myChart" :style="{width: '100%', height: '300px'}"> echart </div>
    </gl-card>
    <gl-card>
      <div ref="myLineChart" :style="{width: '100%', height: '300px'}"> line </div>
    </gl-card>
    <gl-card>
      <div ref="myChartPie" :style="{width: '100%', height: '300px'}"></div>
    </gl-card>
    
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {createBar, createLine, createPie, createMeter} from '@/utils/echarts'

export default {
  data(){
    return{
      myChart: null,
    }
  },
  mounted(){
    this.createChartMeter()
    this.createChart()
    this.createChartLine()
    this.createChartPie()
  },
  methods: {
    createChartMeter(){
      const chartData = {
        columns: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        data: [10, 52, 200, 334, 390, 330, 220],
        name: '创建仪表图',
        type: 'meter'
      }
      createMeter(this.$refs.myChartMeter, chartData)
    },
    createChart(){
      const chartData = {
        columns: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        data: [10, 52, 200, 334, 390, 330, 220],
        name: '创建图',
        type: 'line'
      }
      createBar(this.$refs.myChart, chartData)
    },
    createChartLine(){
      const chartData = {
        columns: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        data: [10, 52, 200, 334, 390, 330, 220],
        name: '创建图',
        type: 'line'
      }
      createLine(this.$refs.myLineChart, chartData)
    },
    createChartPie(){
      const chartData = {
        columns: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        data: [10, 52, 200, 334, 390, 330, 220],
        name: '创建饼状图',
        type: 'pie'
      }
      createPie(this.$refs.myChartPie, chartData)
    },
    
  }
}
</script>

<style>

</style>