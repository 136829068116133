<template>
  <gl-card>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleLimit">promiseList </el-button>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleLimit2">promiseList2 </el-button>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleLimit3">promiseList3 </el-button>
    
  </gl-card>
</template>

<script>
const randomBoolean = () => Math.random() >= 0.5
const request = (num) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log('request:', num)
      resolve('成功'+num)
    }, 600)
  })
}
const promiseLimit = (pList, limit) => {
  return new Promise((resolve, reject) => {
    let runCount = 0
    let resolvedCount = 0
    const pListLength = pList.length
    const result = []

    const next = (task, count) => {
      task().then(res => {
        // result.push(res)
        result[count] = res
        resolvedCount ++
        if(pList.length){
          const runTask = pList.shift()
          next(runTask, runCount)
          runCount ++
        }else if(resolvedCount == pListLength){
          resolve(result)
        }
      })
    }
    while(runCount < limit && pList.length){
      const runTask = pList.shift()
      next(runTask, runCount)
      runCount ++
    }
  })
}

export default {
  methods: {
    async handleLimit(){
      const list = Array.from({length: 20}).map((_,idx) => () => request(idx))
      console.log('list:', list)
      const res = await promiseLimit(list, 5)
      console.log('res:', res)
    },
    handleLimit2(){
      const limitRequest = (pList, limit) => {
        return new Promise((resolve, reject) => {
          const pListLength = pList.length
          let count = 0
          let fufilledCount = 0
          const result = []

          const start = (current) => {
            // console.log('current:', current)
            const nextTask = pList.shift()
            nextTask().then(res => {
              
              result[current] = res
            }).catch(err => {

              console.log('nextTask-catch-err:', err)
            }).finally(() => {
              fufilledCount ++
              // console.log('finally:', current)
              if(pList.length){
                // 完成之后启动下一个任务
                start(count)
                count ++ 
              }else if(fufilledCount == pListLength){ //最后一个任务完成

                resolve(result)
              }
            })
          }
          while(count < limit && pList.length){
            start(count)
            count ++
          }
          // while(limit){
          //   start(limit)
          //   limit -= 1
          // }
        })
      }

      const list = Array.from({length: 20}).map((_,idx) => () => request(idx))
      console.log('list:', list)
      limitRequest(list, 3).then(res => {
        console.log('res:', res)
      })
    },
    async handleLimit3(){
      const promiseLimit = (pList, limit) => {
        return new Promise((resolve, reject) => {
          let runCount = 0
          let resolvedCount = 0
          const pListLenght = pList.length
          const result = []

          const next = (task, count) => {
            task().then(res => {
              result[count] = res
              resolvedCount ++
              if(pList.length){
                const nextTask = pList.shift()
                next(nextTask, runCount)
                runCount ++
              }else if(resolvedCount == pListLenght){
                resolve(result)
              }
            }).finally(() => {})
          }
          while(runCount < limit && pList.length){
            const nextTask = pList.shift()
            next(nextTask, runCount)
            runCount ++
          }
        })
      }
      
      const list = Array.from({length: 20}).map((_,idx) => () => request(idx))
      console.log("promise-list:", list)
      const res = await promiseLimit(list, 3)
      console.log('res:', res)
    },
  },
}
</script>

<style>

</style>