<template>
  <gl-card>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleEmit"> messageCenter-emit </el-button>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleClear"> clear </el-button>
    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleEvent"> eventCenter </el-button>
    <div> userInfo: {{userInfo}} </div>
    <div> emitData: {{emitData}} </div>
  </gl-card>
</template>

<script>

class MessageCenter{
  constructor(){
    this.cache = {} //存放-缓存的事件信息
  }
  on(name, callback){
    if(this.cache[name]){
      this.cache[name].push(callback)
    }else{
      this.cache[name] = [callback]
    }
  }
  emit(name, data){
    if(this.cache[name]){
      const tasks = this.cache[name].slice()
      for(let task of tasks){
        task(data)
      }
    }
  }
  off(name, callback){
    const index = this.cache[name].indexOf(callback)
    if(index != -1){
      this.cache[name].splice(index, 1)
    }
  }
  //监听执行一次
  once(name, callback){
    console.log('once-name:', name)
    const one = (...args) => {
      callback(...args)
      this.off(name, one)
    }
    this.on(name, one)
  }
}
const messageCenter = new MessageCenter()

const addTask = (name, callback) => messageCenter.on(name, callback)
const add_task = (name, callback) => messageCenter.once(name, callback)
const emitTask = (name, data) => messageCenter.emit(name, data)
const removeTask = (name, callback) => messageCenter.off(name, callback)

export default {
  data(){
    return{
      userInfo: {name: 'messageCenter', class: 'MessageCenter'},
      emitData: {},
    }
  },
  methods: {
    handleEmit(){
      emitTask('user--change', this.userInfo)
    },
    handleClear(){
      this.emitData = {}
    },
    change(data){
      console.log('data:', data)
      this.emitData = data
    },
    handleEvent(){
      class EventEmitter{
        constructor(){
          this.cache = {}
        }
        on(name, callback){
          if(this.cache[name]){
            this.cache[name].push(callback)
          }else{
            this.cache[name] = [callback]
          }
        }
        emit(name, data){
          if(this.cache[name]){
            const tasks = this.cache[name].slice()
            for(const fn of tasks){
              fn(data)
            }
          }
        }
        off(name, callback){
          const index = this.cache[name].indexOf(callback)
          if(index != -1){
            this.cache[name].splice(index, 1)
          }
        }
        once(name, callback){
          const one = (...args) => {
            callback(...args)
            this.off(name, one)
          }
          this.on(name, one)
        }
      }
      const eventCenter = new EventEmitter()
      eventCenter.on('user--change', data => {
        this.emitData = data
      })
      eventCenter.emit('user--change', {
        name: 'eventCenter',
        class: 'EventEmitter'
      })

    },
  },
  mounted(){
    addTask('user--change', this.change)
    // add_task('user--change', this.change)
  },
  destroyed(){
    removeTask('user--change', this.change)
  },
}
</script>

<style>

</style>