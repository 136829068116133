<template>
  <div>
    <gl-card>
      <div v-for="(item,index) of images" :key="index">
        <!-- <img :src="item" data-src="https://vitejs.cn/logo.svg" alt="" width="300"> -->
        <el-image :src="item" lazy style="width:300px"></el-image>
      </div>
    </gl-card>
  </div>
</template>

<script>
export default {
  name: 'lazy',
  data(){
    return {
      images: [
        'https://web-assets.dcloud.net.cn/unidoc/zh/uni-app.png',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683706916555.png',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683712517648.jpg',

        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683772758939.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683772759453.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1683772759204.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1684724288097.png',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1684724304508.jpg',
        'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/category/1685945009478.jpg',
      ],
    }
  }
}
</script>

<style>

</style>