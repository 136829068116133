import Vue from 'vue'
import messageBoxVue from './messageBox.vue'

const MessageBoxInstance = Vue.extend(messageBoxVue)
let instance = null

const messageBox = (options) => {
  
  instance = new MessageBoxInstance({
    // propsData: {...options},  // 组件内props
    ...options,
    clickFun: res => {
      options.click && options.click(res)
      remove()
    },
    success: res => {
      options.success && options.success(res)
    },
    cancel: () => {
      options.cancel && options.cancel()
    }
  })
  // Object.assign(instance, options)
  console.log('instance:', instance)
  
  const render = () => document.body.appendChild(instance.$mount().$el)
  render()

  const remove = () => document.body.removeChild(instance.$mount().$el)
}

export default messageBox