<template>
  <div class="box">
    <div :class="['box-main ', { active: isShow }]">{{ message }}==={{timestamp}}</div>
  </div>
</template>

<script>
export default {
  name: 'TIP',
  data() {
    return {
      message: "",
      timestamp: '',
      isShow: false,
    };
  },
  mounted() {
    console.log('mounted')
    setTimeout(() => {
      this.isShow = true;
    }, 2000);
  },
  methods: {},
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
  /* position: relative; */
  position: fixed;
  top: 0;
}
.box-main {
  max-width: 350px;
  min-height: 50px;
  background: #ccc;
  text-align: center;
  line-height: 50px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.active {
  transition: all 1s;
  opacity: 0;
}
</style>