


import * as echarts from 'echarts'

/**
 * 
 * @param {*element} elem 
 * @param {object} chartData 
 */
export const createBar = (elem, chartData) => {
  const mapType = ['line','bar','pie']  // line:折线 bar:柱状 pie:饼状
  const container = elem || document.getElementById('container')
  const {columns, data, nmae = 'name', type = 'line'} = chartData

  var myChart = null
  if(myChart != null){
    myChart.dispose() //解决echarts dom已经加载的报错
  }

  myChart = echarts.init(container)

  const option = {
    title: {
      text: 'echarts',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    legend: {
      // data: ['图标'],
      // data: ['1', '2','3','4','5','6','7'],
    },
    xAxis: [
      {
        type: 'category',
        booundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        nameGap: 20,
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',  // line:折线 bar:柱状 pie:饼状
        barWidth: '60%',
        data: [10, 52, 200, 334, 390, 330, 220]
      }
    ]
  }

  option && myChart.setOption(option)

}

export const createLine = (elem, chartData) => {
  const container = elem || document.getElementById('container')
  const {columns, data, nmae = 'name', type = 'line'} = chartData

  var myChart = null
  if(myChart != null){
    myChart.dispose() //解决echarts dom已经加载的报错
  }

  myChart = echarts.init(container)

  const option = {
    title: {
      text: 'echarts',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    legend: {
      // data: ['图标'],
      // data: ['1', '2','3','4','5','6','7'],
    },
    xAxis: [
      {
        type: 'category',
        booundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        nameGap: 20,
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'line',  // line:折线 bar:柱状 pie:饼状
        barWidth: '60%',
        data: [10, 52, 200, 334, 390, 330, 220]
      }
    ]
  }

  option && myChart.setOption(option)
}

// import {echart} from 'echarts'
// const chart = echart({})

export const createPie = (dom, chartData) => {
  const container = dom || document.getElementById('container')
  const {columns, data, nmae = 'name', type = 'line'} = chartData

  const myChart = echarts.init(container)

  const option = {
    title: {
      text: '饼状图',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1048, name: 'Search Engine' },
          { value: 735, name: 'Direct' },
          { value: 580, name: 'Email' },
          { value: 484, name: 'Union Ads' },
          { value: 300, name: 'Video Ads' }
        ]
      }
    ]
  }

  option && myChart.setOption(option)
}

//创建仪表盘图表
export const createMeter = (elem, chartData) => {
  const container = elem || document.getElementById('container')
  const {columns, data, nmae = 'name', type = 'line'} = chartData

  const myChart = echarts.init(container)

  const option = {
    title: {
      text: '仪表盘',
    },
    tooltip: {
      formatter: '{a} <br/>{b} : {c}%'
    },
    series: [
      {
        name: 'Pressure',
        type: 'gauge',
        progress: {
          show: true
        },
        detail: {
          valueAnimation: true,
          formatter: '{value}'
        },
        data: [
          {
            value: 50,
            name: 'SCORE'
          }
        ]
      }
    ]
  }
  option && myChart.setOption(option)
}

export default {
  createBar,
  createPie,
  createLine,
  createMeter,
}