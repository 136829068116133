<template>
  <div>
    <gl-title title="组件测试"></gl-title>
    <PromiseList />
    <Observer />
    <MessageCenter />
    <Toast />
    <MessageBox />
    <EchartTemp />
    <TestFetch/>
    <gl-card>
      <!-- 动态表单 -->
      <gl-dynamic-form>
        <template v-slot:custom>
          <el-form-item label="描述2">
            <el-input type="textarea" :rows="5" placeholder="请输入描述" :maxlength="50"></el-input>
          </el-form-item>
        </template>
      </gl-dynamic-form>
    </gl-card>
    
    <!-- <Lazy/> -->
    <!-- <DragUpload /> -->
  </div>
</template>

<script>
import DragUpload from './test/dragUpload.vue'
import Lazy from './test/lazy.vue'
import TestFetch from './test/testFetch.vue'
import EchartTemp from './test/echartTemp.vue'
import MessageBox from './test/messageBox'
import Toast from './test/toast'
import MessageCenter from './test/messageCenter.vue'
import Observer from './test/observer.vue'
import PromiseList from './test/promiseLimit.vue'
export default {
  name: 'componentTemp',
  components: {DragUpload,Lazy,TestFetch, EchartTemp, MessageBox, Toast, MessageCenter, PromiseList, Observer},
}
</script>

<style>

</style>